import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loginDialogIsShow: false,
    loginDialogType: 0,
    userInfo: null,
    orderInfo: null,
    payOrderId: null,
    question: {},
  },
  mutations: {
    setUserInfo (state, payload) {
      state.userInfo = payload
    },
    clearUserInfo(state, payload) {
      state.userInfo = payload
    },
    toggleDialog(state, payload) {
      state.loginDialogIsShow = payload
    },
    setDialogType(state, payload) {
      state.loginDialogType = payload
    },
    clearOrderInfo(state, payload) {
      state.orderInfo = payload
    },
    setOrderInfo(state, payload) {
      state.orderInfo = payload
    },
    setPayOrderId(state, payload) {
      state.payOrderId = payload
    },
    setQuestion(state, {key, value}) {
      state.question[key] = value
    }
  },
  actions: {
    setUserInfo(context,payload) {
      context.commit('setUserInfo', payload)
    },
    clearUserInfo(context,payload) {
      context.commit('clearUserInfo', null)
    },
    toggleDialog(context,payload) {
      context.commit('toggleDialog', payload.isShow)
      context.commit('setDialogType', payload.type || 0)
    },
    clearOrderInfo(context,payload) {
      context.commit('clearOrderInfo', {})
    },
    setOrderInfo(context,payload) {
      context.commit('setOrderInfo', payload)
    },
    setPayOrderId(context,payload) {
      context.commit('setPayOrderId', payload)
    },
    setQuestion(context, payload) {
      context.commit('setQuestion', payload)
    }
  },
  modules: {
  },
  plugins: [createPersistedState({
    storage: window.localStorage,
    reducer(val) {
      return {
        userInfo: val.userInfo,
        orderInfo: val.orderInfo
      }
    }
  })]
})
