<!--
 * @Description  : 
 * @Author       : XiaoLei
 * @Date         : 2021-03-26 19:12:56
 * @LastEditTime : 2023-03-29 10:44:19
 * @LastEditors  : BigBigger
-->
<template>
  <div id="app">
    <Nav />
    <router-view/>
    <Foot />
    <Side v-if="isShow" />
  </div>
</template>

<style lang="less" scoped>

</style>

<script>
import Nav from '@/components/layouts/Nav'
import Foot from '@/components/layouts/Foot'
import Side from '@/components/layouts/Side'
export default {
  name: 'App',
  components: {
    Nav,
    Foot,
    Side
  },
  data(){
    return{
      isShow:true,
    }
  },
  mounted(){
    if(process.env.VUE_APP_AREA === 'js'){
      var script = document.createElement("script");
      script.setAttribute('id', 'qd2852137318fb88e903cbc00f047975e86536993f98');
      script.setAttribute('src', 'https://tb.53kf.com/code/code/3a0b73d90adc76573a8179fe7a25ebed0/1'); // 江苏
      // script.setAttribute('src', 'https://tb.53kf.com/code/code/6baddf3f3fa4c624cba1c4be4ac8ea985/1'); // 安徽
      document.body.appendChild(script);
      this.isShow = false

      let jumpWebUrl = 'https://web.jsbxgwy.com/'
      let jumpH5Url = 'https://webh5.jsbxgwy.com/'

      //江苏-备份服务器
      // let jumpWebUrl = 'https://webbackup.jsbxgwy.com/'
      // let jumpH5Url = ' https://webh5backup.jsbxgwy.com/'


      var nowUrl = window.location.href;
      var url = location.pathname.substring(1);
      try {
        var urlhash = window.location.hash;
        if (!urlhash.match("fromapp")){
          if ((navigator.userAgent.match(/(iPhone|iPod|Android|ios|iPad)/i)))
          {
            if (url.indexOf('category') !== -1) {
              window.location=`${jumpH5Url}${url}`;
              return;
            }
            if (url.indexOf("course/") !== -1){
              window.location=`${jumpH5Url}${url}`
            }else{
              window.location= jumpH5Url
            }
          }
        }
      }
      catch(err){

      }

    }else{
      // let jumpWebUrl = process.env.NODE_ENV === 'production' ? 'https://web.bxgwy.com/' : 'http://webdev.bxgwy.com/' 
      // let jumpH5Url = process.env.NODE_ENV === 'production' ? 'https://webh5.bxgwy.com/' : 'http://webh5dev.bxgwy.com/'

      //浙江-备份服务器
      let jumpWebUrl =  'https://webbackup.bxgwy.com/' 
      let jumpH5Url =  'https://webh5backup.bxgwy.com/'

      var nowUrl = window.location.href;
      var url = nowUrl.split(jumpWebUrl)[1]
      try {
        var urlhash = window.location.hash;
        if (!urlhash.match("fromapp")){
          if ((navigator.userAgent.match(/(iPhone|iPod|Android|ios|iPad)/i)))
          { 
            if(url.indexOf("course/") !== -1){
              window.location=`${jumpH5Url}${url}`
            }else{
              window.location= jumpH5Url
            }
            
          }
        }
      }
      catch(err){

      }
    }
  }
}
</script>
